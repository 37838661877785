import React, { useState } from "react";
import Layout from "../../components/layout";
import Herobanner from "../../components/herobanner";
import BrandCarousel from "../../components/brandcarousel";
import CallOutSection from "../../components/calloutsection";
import Joblist from "../../components/joblist";
import { SEO } from "../../components/seo";
import { graphql } from "gatsby";
import { getPageByLanguage } from "../../hooks/get-page-by-language";
import useCookie from "../../hooks/use-cookie";
import { getBrowserLanguage } from "../../hooks/get-language";
import { getHeaderAndFooter } from "../../hooks/get-header-and-footer";

const Careers = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let foundCareers = getPageByLanguage(
    data.allContentstackCareers.nodes,
    false,
    cookie
  );
  let foundJobPostings = getPageByLanguage(
    data.allContentstackJobPostings.nodes,
    false,
    cookie,
    true
  );
  let foundLocationList = getPageByLanguage(
    data.allContentstackLocationList.nodes,
    false,
    cookie
  );
  let foundDepartmentList = getPageByLanguage(
    data.allContentstackDepartmentList.nodes,
    false,
    cookie
  );
  let foundJobPostingDuplicateContent = getPageByLanguage(
    data.allContentstackJobPostingDuplicateContent.nodes,
    false,
    cookie
  );

  const isBrowser = typeof window !== "undefined";
  if (!foundCareers && isBrowser) {
    navigate("/404/");
  }

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <Herobanner herobanner={foundCareers.hero_banner[0]} />
      <CallOutSection calloutsection={foundCareers.call_out_section[0]} />
      <Joblist
        jobs={foundJobPostings}
        locations={foundLocationList.locations}
        departments={foundDepartmentList.departments}
        jobDuplicateContent={foundJobPostingDuplicateContent}
      />
      <BrandCarousel carousel={foundCareers.brand_carousel} />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackCareers {
      nodes {
        locale
        hero_banner {
          image_group {
            image {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                layout: CONSTRAINED
              )
            }
            image_position
          }
          secondary_header
          secondary_header_text
          text
          title
        }
        brand_carousel {
          title
          link {
            href
            title
          }
          background_image {
            url
            title
          }
          global_field {
            logo_title
            logo_image {
              url
              title
            }
          }
        }
        call_out_section {
          section_title
          card_layout_3
          title_as_link_
          call_out_cards {
            title
            text
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            link {
              href
              title
            }
          }
        }
      }
    }
    allContentstackJobPostings {
      nodes {
        locale
        title
        updated_at
        position_overview_content
        locations {
          location_list
        }
        department {
          department_list
        }
      }
    }
    allContentstackLocationList {
      nodes {
        locale
        locations {
          key
        }
      }
    }
    allContentstackDepartmentList {
      nodes {
        locale
        departments {
          key
        }
      }
    }
    allContentstackJobPostingDuplicateContent {
      nodes {
        locale
        title
        main_content
        bottom_content
        open_opportunities_label
        location_label
        department_label
        date_label
        position_overview_label
        responsibilities_label
        minimum_qualifications_label
        preferred_qualifications_label
        physical_requirements_label
        salary_label
        apply_link_text
        how_to_apply_label
        back_link_text
        learn_more_link_text
      }
    }
  }
`;

export default Careers;
export const Head = () => <SEO title="Elevate Outdoor Collective | Careers" />;
