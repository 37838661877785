import React from "react";
import { Link } from "gatsby";
import parse from "html-react-parser";
import * as jobcardStyles from "../styles/components/jobcard.module.scss";
import * as accents from "remove-accents";

const Jobcard = ({ job, jobDuplicateContent }) => {
  let findUrl = job.title
    .split(" ")
    .map((el) => {
      let lowercaseTitle = el.toLowerCase();
      let removeAccentsTitle = accents.remove(lowercaseTitle);
      return removeAccentsTitle.replace(/[^a-zA-Z0-9-_]/g, "");
    })
    .join("");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date = new Date(job.updated_at);
  let getDayYear = date.toDateString().split(" ").splice(2);
  let formattedDate = [
    monthNames[date.getMonth()],
    `${getDayYear[0]},`,
    getDayYear[1],
  ].join(" ");
  let locationList = job.locations.map((el, idx) => {
    if (idx + 1 === job.locations.length) {
      return el.location_list;
    } else {
      return el.location_list + "; ";
    }
  });
  return (
    <article className={jobcardStyles.jobCard}>
      <h4 className={jobcardStyles.cardTitle}>{job.title}</h4>
      <div className={jobcardStyles.detailsContainer}>
        <p>
          {jobDuplicateContent?.location_label}: {locationList}
        </p>
        <p>
          {jobDuplicateContent?.department_label}:{" "}
          {job.department.department_list}
        </p>
        <p>
          {jobDuplicateContent?.date_label}: {formattedDate}
        </p>
      </div>
      <p className={jobcardStyles.textContainer}>
        {parse(job.position_overview_content)}
      </p>
      <Link className="cta-small link" to={`/careers/${findUrl}`}>
        {jobDuplicateContent?.learn_more_link_text}
      </Link>
    </article>
  );
};

export default Jobcard;
