import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Jobcard from "./jobcard";
import { Multiselect } from "multiselect-react-dropdown";
import * as joblistStyles from "../styles/components/joblist.module.scss";
import classNames from "classnames";

const Joblist = ({ jobs, locations, departments, jobDuplicateContent }) => {
  const PER_PAGE = 4;
  const anyOption = { key: "Any" };
  const allLocations = [anyOption, ...locations];
  const allDepartments = [anyOption, ...departments];
  const [dropdowns, setDropdowns] = useState({
    location: "Any",
    department: "Any",
  });
  let sortJobs = jobs.sort(
    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
  );
  const [allJobs, setJobs] = useState(sortJobs);
  const [currentPage, setCurrentPage] = useState(0);

  const updateDropdown = (name, type) => {
    let dropdownOptions = { ...dropdowns };
    dropdownOptions[type] = name;
    setDropdowns(dropdownOptions);
    let filteredJobs = sortJobs.filter((job) => {
      if (
        dropdownOptions.location.includes("Any") &&
        dropdownOptions.department.includes("Any")
      ) {
        return job;
      } else if (dropdownOptions.location.includes("Any")) {
        return job.department.department_list === dropdownOptions.department;
      } else if (dropdownOptions.department.includes("Any")) {
        return job.locations.some(
          (location) => location.location_list === dropdownOptions.location
        );
      } else {
        return (
          job.locations.some(
            (location) => location.location_list === dropdownOptions.location
          ) && job.department.department_list === dropdownOptions.department
        );
      }
    });

    setJobs(filteredJobs);
    setCurrentPage(0);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    window?.scrollTo(0, 0);
    document.getElementById("title").scrollIntoView();
  };

  const offset = currentPage * PER_PAGE;
  const currentPageData = allJobs.slice(offset, offset + PER_PAGE);
  const pageCount = Math.ceil(allJobs.length / PER_PAGE);

  return (
    <section className={joblistStyles.jobList}>
      <h2 id="title" className={joblistStyles.mainTitle}>
        {jobDuplicateContent?.open_opportunities_label}
      </h2>
      <div className={joblistStyles.dropdownContainer}>
        <label
          className={classNames(joblistStyles.locationDropdown, "label-medium")}
        >
          {jobDuplicateContent?.location_label}
          <Multiselect
            options={allLocations}
            placeholder="Any"
            aria-label="Location Dropdown"
            singleSelect
            displayValue="key"
            onSelect={(selectedList) =>
              updateDropdown(selectedList[0].key, "location")
            }
          />
        </label>
        <label
          className={classNames(
            joblistStyles.departmentDropdown,
            "label-medium"
          )}
        >
          {jobDuplicateContent?.department_label}
          <Multiselect
            options={allDepartments}
            placeholder="Any"
            aria-label="Department Dropdown"
            singleSelect
            displayValue="key"
            onSelect={(selectedList) =>
              updateDropdown(selectedList[0].key, "department")
            }
          />
        </label>
      </div>
      <div className={joblistStyles.jobsContainer}>
        {currentPageData.map((job, idx) => (
          <Jobcard
            job={job}
            key={idx}
            jobDuplicateContent={jobDuplicateContent}
          />
        ))}
      </div>
      <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={joblistStyles.pagination}
        previousLinkClassName={joblistStyles.paginationLink}
        nextLinkClassName={joblistStyles.paginationLink}
        disabledClassName={joblistStyles.linkDisabled}
        activeClassName={joblistStyles.linkActive}
        forcePage={currentPage}
      />
    </section>
  );
};

export default Joblist;
