// extracted by mini-css-extract-plugin
export var btnDark = "jobcard-module--btn-dark--88420";
export var btnLight = "jobcard-module--btn-light--21311";
export var buttonDefault = "jobcard-module--button-default--ed265";
export var buttonLarge = "jobcard-module--button-large--3771d";
export var buttonSmall = "jobcard-module--button-small--80f9d";
export var cardTitle = "jobcard-module--card-title--9f645";
export var ctaLarge = "jobcard-module--cta-large--b8f17";
export var ctaSmall = "jobcard-module--cta-small--3cad0";
export var detailsContainer = "jobcard-module--details-container--1f3f0";
export var highlight = "jobcard-module--highlight--6a2f7";
export var input = "jobcard-module--input--17480";
export var jobCard = "jobcard-module--job-card--bec65";
export var labelLarge = "jobcard-module--label-large--f20f8";
export var labelMedium = "jobcard-module--label-medium--f2c3d";
export var languages = "jobcard-module--languages--41752";
export var link = "jobcard-module--link--2de40";
export var optionContainer = "jobcard-module--optionContainer--e9fc0";
export var optionListContainer = "jobcard-module--optionListContainer--4ea4a";
export var pLarge = "jobcard-module--p-large--738de";
export var pLargeBold = "jobcard-module--p-large-bold--9b5d2";
export var pMedium = "jobcard-module--p-medium--b51e3";
export var pSmall = "jobcard-module--p-small--506e1";
export var searchWrapper = "jobcard-module--search-wrapper--ee835";
export var singleChip = "jobcard-module--singleChip--811e0";
export var slickNext = "jobcard-module--slick-next--271f3";
export var slickPrev = "jobcard-module--slick-prev--aa063";
export var slickSlide = "jobcard-module--slick-slide--e0abb";
export var textContainer = "jobcard-module--text-container--b79f9";
export var titleLarge = "jobcard-module--title-large--5a4f6";
export var titleMedium = "jobcard-module--title-medium--1f97c";
export var titleSmall = "jobcard-module--title-small--e1f51";
export var titleXl = "jobcard-module--title-xl--f5c0f";